import React from 'react';
import '../pagecss/Affiliations.css';
import cairNepalImage from '../assets/cair-nepal.png';
import leadSemanticsImage from '../assets/lead-semantics.png'; 

const Affiliations = () => {
  return (
    <div className="partners-section">
      <div className="partners-content">
        <h2>Affiliation with Z-Inspection® Initiative</h2>
        <p>The ShodhGuru Innovation and Research Labs is Affiliated with the Z-Inspection® Initiative.
 <br></br><br></br>
 It adopts the Z-Inspection® process to the assessment of the trustworthiness of real-life AI systems and applications.
 <br></br> <br></br>
 Z-Inspection® is a holistic process used to evaluate the trustworthiness of AI-based technologies at different stages of the AI lifecycle. It focuses, in particular, on the identification and discussion of ethical issues and tensions through the elaboration of socio-technical scenarios. 
 <br></br> <br></br>
 The Z-Inspection® process is distributed under the terms and conditions of the Creative Commons (Attribution-Non Commercial-Share Alike CC BY-NC-SA) license.
 <br></br> <br></br>
 Z-Inspection® is listed in the new OECD Catalogue of AI Tools & Metrics.
 </p>
        
      </div>
      
    </div>
  );
}

export default Affiliations;
